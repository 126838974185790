<template>
  <div class="sidebar-detached sidebar-left">
    <div class="sidebar">
      <div
        class="sidebar-shop filter pl-1"
        :class="{'show': mqShallShowLeftSidebar}"
      >
        <div
          v-if="mqShallShowLeftSidebar"
          class="sidebar-header"
        >
          <feather-icon
            icon="XIcon"
            size="20"
            class="close-button"
            @click="$emit('update:mq-shall-show-left-sidebar', false)"
          />
        </div>
        <b-row>
          <b-col
            cols="12"
            class="mb-1"
          />
        </b-row>

        <!-- Filters' Card -->

        <!-- Multi Range -->
        <!--          <div class="multi-range-price">-->
        <!--            <h6 class="filter-title mt-0">-->
        <!--              Multi Range-->
        <!--            </h6>-->
        <!--            <b-form-radio-group-->
        <!--              v-model="filters.priceRangeDefined"-->
        <!--              class="price-range-defined-radio-group"-->
        <!--              stacked-->
        <!--              :options="filterOptions.priceRangeDefined"-->
        <!--            />-->
        <!--          </div>-->

        <!-- Price Slider -->
        <!--          <div class="price-slider">-->
        <!--            <h6 class="filter-title">-->
        <!--              Price Range-->
        <!--            </h6>-->
        <!--            <vue-slider-->
        <!--              v-model="filters.priceRange"-->
        <!--              :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
        <!--            />-->
        <!--          </div>-->

        <!-- Purposes -->
        <div
          v-if="options.pointPurposes.length>0"
          class="purpose"
        >
          <h6 class="filter-title">
            {{ $t('Purposes') }}
          </h6>
          <b-form-checkbox-group
            v-model="filters.pointPurposes"
            stacked
            :options="options.pointPurposes"
            class="checkbox-group"
            @change="$emit('update:mq-shall-show-left-sidebar', false)"
          />
        </div>
        <!-- Authors -->
        <!--          <div class="author">-->
        <!--            <h6 class="filter-title">-->
        <!--              Authors-->
        <!--            </h6>-->
        <!--            <b-form-checkbox-->
        <!--              v-model="filters.userId"-->
        <!--              :value="options.userId"-->
        <!--              class="custom-control-primary"-->
        <!--            >-->
        <!--              Me-->
        <!--            </b-form-checkbox>-->
        <!--          </div>-->
        <!-- Post Statuses -->
        <div class="status">
          <h6 class="filter-title">
            {{ $t('Status') }}
          </h6>
          <b-form-checkbox-group
            v-model="filters.postStatuses"
            stacked
            :options="options.postStatuses"
            class="checkbox-group"
          />
        </div>
        <!-- Post Owner -->
        <div class="owner">
          <h6 class="filter-title">
            {{ $t('Author') }}
          </h6>
          <b-form-radio-group
            v-model="filters.postOwner"
            stacked
            :options="options.postOwner"
            class="radio-group"
          />
        </div>
        <!-- Progress -->
        <div>
          <h6 class="filter-title">
            {{ $t('point.progress') }}
          </h6>
          <div class="checkbox-group">
            <b-form-checkbox
              v-model="filters.noEvents"
              name="no-events"
              inline
              class="custom-control"
            >
              {{ $t('point.no_events') }}
            </b-form-checkbox>
            <b-form-checkbox
              v-model="filters.noViews"
              name="no-views"
              inline
            >
              {{ $t('point.no_views') }}
            </b-form-checkbox>
          </div>

        </div>
        <!-- Ratings -->
        <!--          <div class="ratings">-->
        <!--            <h6 class="filter-title">-->
        <!--              Ratings-->
        <!--            </h6>-->
        <!--            <div-->
        <!--              v-for="rating in filterOptions.ratings"-->
        <!--              :key="rating.rating"-->
        <!--              class="ratings-list"-->
        <!--            >-->
        <!--              <b-link>-->
        <!--                <div class="d-flex">-->
        <!--                  <feather-icon-->
        <!--                    v-for="star in 5"-->
        <!--                    :key="star"-->
        <!--                    icon="StarIcon"-->
        <!--                    size="17"-->
        <!--                    :class="[{'fill-current': star <= rating.rating}, star <= rating.rating ? 'text-warning' : 'text-muted']"-->
        <!--                  />-->
        <!--                  <span class="ml-25">&amp; up</span>-->
        <!--                </div>-->
        <!--              </b-link>-->
        <!--              <div class="stars-received">-->
        <!--                <span>{{ rating.count }}</span>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->

      </div>
    </div>

    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="$emit('update:mq-shall-show-left-sidebar', false)"
    />
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BFormRadioGroup, BLink, BFormCheckboxGroup, BFormCheckbox, BButton, BFormGroup,
  } from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import { postStatuses } from '@/mixins/options'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BLink,
    BCard,
    BFormCheckbox,
    BButton,
    BFormGroup,
    // 3rd Party
    VueSlider,
  },
  directives: {
    Ripple,
  },
  mixins: [postStatuses],
  props: {
    filters: {
      type: Object,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      options: this.filterOptions,
    }
  },
  watch: {
    '$i18n.locale': function (newVal, oldVal) {
      this.getOptionPostStatuses()
      this.getOptionPostOwner()
    },
  },
  mounted() {
    // this.fetchPurposes()
    this.getOptionPostStatuses()
    this.getOptionPostOwner()
  },
  methods: {
    // fetchPurposes() {
    //   store.dispatch('point/getPointTypesAndPurposes')
    //     .then(response => {
    //       if (response.data) {
    //         this.options.pointPurposes = this.getOptionPurposes(response.data)
    //       }
    //     })
    //     .catch(error => {
    //       console.log(error)
    //       if (error.response.status === 404) {
    //         this.options.pointPurposes = undefined
    //       }
    //     })
    //
    //   this.getOptionUserId()
    // },
    // getOptionPurposes(typesAndPurposes) {
    //   const result = []
    //   const optionsPurposes = this.filterOptions.pointPurposes.value || null
    //   const serviceRequestPurposes = typesAndPurposes.filter(item => item.slug === 'service_request')
    //   if (serviceRequestPurposes) {
    //     serviceRequestPurposes.forEach(type => {
    //       Object.values(type.point_purposes).forEach(purpose => {
    //         const qty = optionsPurposes !== null ? optionsPurposes.filter(p => p.id === purpose.id).map(p => p.qty).join() : null
    //         if (qty) {
    //           result.push({ text: this.$t(purpose.title), value: purpose.id, qty })
    //         }
    //       })
    //     })
    //   }
    //   return result
    // },
    // getOptionUserId() {
    //   const user = store.getters['account/user']
    //   this.options.userId = user.id
    // },
    getOptionPostStatuses() {
      const result = []
      const statuses = this.postStatuses.filter(status => status.slug !== 'incomplete')

      Object.values(statuses).forEach(status => {
        result.push({ text: this.$t(status.title), value: status.slug })
      })
      this.options.postStatuses = result
    },
    getOptionPostOwner() {
      const result = []

      this.options.postOwner.forEach(owner => {
        result.push({ text: this.$t(`${'common.'.concat(owner.value)}`), value: owner.value })
      })
      this.options.postOwner = result
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-slider.scss';
</style>

<style lang="scss" scoped>
// Color palettes
@import '~@core/scss/base/core/colors/palette-variables.scss';
@import '~@core/scss/base/components/include'; // Components includes
.checkbox-group,
.radio-group,
.price-range-defined-radio-group {
  ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}
.sidebar-shop .sidebar-header .close-button{
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 1;
}
.sidebar-shop.filter.show {
  overflow: auto;
  background-color: $white;
}

.dark-layout .sidebar-shop.filter.show {
  background-color: $theme-dark-card-bg;
}
</style>
